import http from './http-common';
class OptionService {
  getOption(optionId: string) {
    return http.get(`option/${optionId}`);
  }

  getAllOption() {
    return http.get<any>('option/');
  }

  updateOption(optionId: any, data: any) {
    return http.post(`option/update/${optionId}`, data);
  }

  createOption(data: any) {
    return http.post('option/', data);
  }

  removeOption(optionId: any) {
    return http.get(`option/delete/${optionId}`);
  }
}
export default new OptionService();
