import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAccessToken } from '../../services/http-common';
import { BASEURL, API_SECRET, API_KEY } from '../Constants';
import Breadcrumb from '../../components/Breadcrumb';
import { Link } from 'react-router-dom';
import SearchOrder from '../../components/SearchOrder';
interface orderInterface {
  orderId?: any | null;
  amount: string;
  status: string;
  paymentMode:string;
  date: string;
  orderTotal:string;
}

interface OrderPeginationData{
  totalElements:number;
  numberOfElements:number;
  size:number;
}

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [orderPagination, setOrderPagination]=useState<OrderPeginationData>();
  const perPage = orderPagination?.size || 10;
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchOrder, setSearchOrder] = useState(false);
  const [orders, setOrders] = useState<orderInterface[]>([]);
  const startItem = currentPage * perPage + 1;
  const endItem = Math.min(startItem + perPage - 1, orderPagination?.totalElements ?? 0);
  const accessToken = getAccessToken();
  useEffect(() => {
    fetchData(currentPage, perPage);
  }, [currentPage, perPage]); // Empty dependency array means this effect runs only once after the component mounts
  const fetchData = async (page: number, perPage: any) => {
    try {
      const response = await axios.get(`${BASEURL}orders/get`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Type': 'multipart/form-data',
          'X-API-SECRET': `${API_SECRET}`,
          'X-API-KEY': `${API_KEY}`,
        },
        params: {
          page,
          size: perPage,
        },
        method: 'GET',
      });
      const ordersData = await response.data.content;
      setOrderPagination(response.data);
      setTotalPages(response.data.totalPages);
      setOrders(ordersData);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      return null;
    }
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    fetchData(currentPage + 1, perPage);
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
      fetchData(currentPage - 1, perPage);
    }
  };

  return (
    <>
      <Breadcrumb pageName="Orders" />

      <div className="rounded-sm border border-stroke bg-white px-5 pt-3 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="flex justify-end pb-3 w-full ">
          <button
            type="submit"
            onClick={() => setSearchOrder(true)}
            className=" inline-block rounded bg-primary px-4 py-2 font-medium text-gray hover:bg-opacity-90"
          >
            Search Order
          </button>
          {searchOrder && (
            <SearchOrder onCancel={() => setSearchOrder(false)} />
          )}
        </div>
        <div className="hidden xl:block flex-col">
          <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-5">
            <div className="p-2.5 xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Order Id
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Date
              </h5>
            </div>
            {/* <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Amount
            </h5>
          </div> */}
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Status
              </h5>
            </div>
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Amount
              </h5>
            </div>
            <div className="hidden p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Actions
              </h5>
            </div>
          </div>

          {orders &&
            orders.map((order, index) => (
              <div
                key={index}
                className="grid grid-cols-3 border-b border-stroke dark:border-strokedark sm:grid-cols-5"
              >
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <p className="hidden text-black dark:text-white sm:block">
                    <Link
                      className="hover:text-primary text-primary"
                      to={`/orders/details/${order.orderId}`}
                    >
                      {order.orderId}
                    </Link>
                  </p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="text-black dark:text-white">
                    {order.date.toString().split('T')[0]}
                  </p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="text-meta-3">{order.status==="PAYMENT_DONE" && order.paymentMode==="cod" ? "PARTIAL_PAYMENT": order.status}</p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-black dark:text-white">{order.orderTotal}</p>
                </div>
                <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
                  <Link
                    className="hover:text-primary"
                    to={`/orders/details/${order.orderId}`}
                  >
                    <svg
                      className="fill-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                        fill=""
                      />
                      <path
                        d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                        fill=""
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            ))}
        </div>

        <div className="xl:hidden sm:block  flex-col">
          <div className="flex justify-between rounded-sm bg-gray-2 dark:bg-meta-4 ">
            <div className="p-2.5 xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Order Id
              </h5>
            </div>
            <div className="p-2.5 xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Amount
              </h5>
            </div>

            <div className=" p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                Action
              </h5>
            </div>
          </div>

          {orders &&
            orders.map((order, index) => (
              <div
                key={index}
                className="flex  justify-between border-b border-stroke dark:border-strokedark "
              >
                <Link
                  className="text-primary"
                  to={`/orders/details/${order.orderId}`}
                >
                  <div className="flex items-center  p-2.5 xl:p-5">
                    <p className="  dark:text-white ">{order.orderId}</p>
                  </div>
                </Link>

                <div className="flex  p-2.5 xl:p-5">
                  <p className="text-black dark:text-white">{order.amount}</p>
                </div>

                <div className=" items-center p-2.5 sm:flex xl:p-5">
                  <Link
                    className="hover:text-primary"
                    to={`/orders/details/${order.orderId}`}
                  >
                    <svg
                      className="fill-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                        fill=""
                      />
                      <path
                        d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                        fill=""
                      />
                    </svg>
                  </Link>{' '}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="mt-4 flex items-center  justify-center space-x-6">
        <button
          onClick={prevPage}
          disabled={currentPage === 0}
          className="bg-primary hover:bg-opacity-90 text-white font-bold py-2 px-4 mr-2 rounded"
        >
          Prev
        </button>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages - 1}
          className="bg-primary  hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div> */}

    
<div className=" mt-3 sm:flex sm:flex-1 space-y-2 sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing
          <span className="font-medium"> {startItem} </span>
          to
          <span className="font-medium"> {endItem} </span>
          of
          <span className="font-medium"> {orderPagination?.totalElements ?? 0} </span>
          results
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={prevPage}
            disabled={currentPage === 0}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${index === currentPage ? 'bg-indigo-600 text-black' : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} focus:z-20 focus:outline-offset-0`}
              aria-current={index === currentPage ? "page" : undefined}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages - 1}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
    </>
  );
};

export default Orders;
