import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Yup from "yup";
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';
import { getAccessToken } from '../services/http-common';
import { ErrorMessage } from 'formik';

 interface AddCategoryImageInterface{ 
  categoryId:any,
  onCancel:any
 }
const AddCategoryImage = ({ categoryId,onCancel }: AddCategoryImageInterface) => {

  const [errors, setErrors] = useState<Record<string, string>>({}); // Record<string, string> defines an object with string keys and string values
  const [categoryImage, setCategoryImage] = useState<File | null>(null);
  const accessToken = getAccessToken();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCategoryImage(file);
    }
  };
  const schema = Yup.object().shape({
    categoryImage: Yup.mixed().required("Image is required"),
  });

  const onSubmit = async () => {
    try {
      await schema.validate({ categoryImage }, { abortEarly: false });

      const formData = new FormData();
      if (categoryImage) {
        formData.append("categoryImage", categoryImage);
      }

      const response = await axios.post(
        `${BASEURL}category/image/add/${categoryId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'X-API-SECRET': `${API_SECRET}`,
            'X-API-KEY': `${API_KEY}`,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      window.location.href = `/category/details/${categoryId}`;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        // Validation failed, set error messages
        let newErrors: Record<string, string> = {};
        error.inner.forEach((e) => {
          if (e.path) {
            newErrors[e.path] = e.message;
          }
        });
        setErrors(newErrors);
      } else {
        console.error("Error submitting form:", error);
        // Handle other types of errors
      }
    }
  };

  return (
    <div className="fixed h-screen  z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg">
        <div className="flex justify-center mb-4">
        <form onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
              }}>            
          <div className="p-6.5">
              <div className=" flex flex-col gap-6 xl:flex-row">
                <div className="w-full">
                  <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                    Category Image<span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="file"
                    name="categoryImage"
                    onChange={handleFileChange}
                    placeholder="select product"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10"
                  />
                  {errors.categoryImage && (
                    <span className="text-sm text-danger">
                      {errors.categoryImage}
                    </span>
                  )}
                </div>
              </div>
              
            </div>
          </form>
          </div>
        <div className="flex space-x-8 item-center justify-center ">
          <button type="submit" className="text-white  focus:ring-4 bg-success hover:bg-green-700 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={onCancel} >Cancel</button>
          <button type="submit" className="text-white bg-primary hover:bg-indigo-800 focus:ring-4  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"  onClick={onSubmit}>Update</button>
        </div>
      
    </div>
    </div>
  );
};

export default AddCategoryImage;